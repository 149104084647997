import * as React from "react";

import { useRecoilValue } from "recoil";

import { useInterval } from "usehooks-ts";

import { Box, Stack, Typography } from "@mui/material";

import { kioskTokenAtom } from "../../state/kioskToken";

import { useKioskData } from "./hooks/useData";
import { TokenForm } from "./TokenForm";
import { DynamicBackground } from "../landingPage/DynamicBackground";
import { NextUpdateCounter } from "./components/NextUpdateCounter";
import { useEffect } from "react";

const REFRESH_INTERVAL_MS = 30000;

export const Dashboard = () => {
  const kioskToken = useRecoilValue(kioskTokenAtom);
  const [nextUpdate, setNextUpdate] = React.useState<number | undefined>();
  const {
    data,
    isLoading,
    isError,
    refresh: refreshData,
  } = useKioskData({ token: kioskToken, auto: true });

  useInterval(() => {
    if (kioskToken === undefined) {
      return;
    }
    refreshData();
  }, REFRESH_INTERVAL_MS);

  useEffect(() => {
    if (data === undefined || isLoading) {
      setNextUpdate(undefined);
      return;
    }
    setNextUpdate(REFRESH_INTERVAL_MS / 1000);
  }, [data, isLoading]);

  if (kioskToken === undefined) {
    return <TokenForm />;
  }

  // total AUM is USD amount in the hundreds of millions
  const lastAumUsd = data?.data.lastAumUsd ?? 0;
  // hash the total AUM to get a pseudo random rotation value, from 0 to 1
  const backgroundHueRotation = (lastAumUsd % 21567) / 21567;

  // percentage of total AUM in billions
  const lastAmountPercent = lastAumUsd / 1_000_000_000;

  // display the data.totalAumUSD value as centered text, with huge font
  // size, formatted as a currency.
  return (
    <>
      <DynamicBackground
        sx={{ filter: `hue-rotate(${backgroundHueRotation}turn)` }}
      />
      <Box
        sx={{
          zIndex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          fontFamily: "monospace",
        }}
      >
        <Stack direction="row" alignItems="baseline">
          <Typography
            fontWeight={"bold"}
            style={{
              fontSize: "14rem",
              color: "white",
              textShadow: "0 0 1rem white",
            }}
          >
            {/* show non-decimals */}
            {Math.floor(lastAmountPercent * 100)}
          </Typography>
          <Typography
            style={{
              fontSize: "7rem",
              color: "white",
              textShadow: "0 0 1rem white",
            }}
          >
            {/* show 4 decimals with zero padding */}.
            {(lastAmountPercent * 100).toFixed(4).split(".")[1]}%
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          sx={{
            position: "absolute",
            bottom: 0,
            right: 0,
            padding: "1rem",
            color: "white",
            fontFamily: "monospace",
            opacity: 0.5,
            zIndex: 1,
          }}
        >
          {isError && "error • "}
          {data !== undefined && (
            <>
              {"last update • "}
              {new Date(data.ts).toLocaleString()}
            </>
          )}
        </Stack>

        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            padding: "1rem",
            color: "white",
            fontFamily: "monospace",
            opacity: 0.5,
            zIndex: 1,
          }}
        >
          <NextUpdateCounter nextUpdate={nextUpdate} />
        </Box>
      </Box>
    </>
  );
};
