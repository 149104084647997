import * as React from "react";

import { RecoilRoot } from "recoil";

import { GlobalStyles, ThemeProvider } from "@mui/material";

import { getTheme } from "../../mui/theme";

import { Dashboard } from "./Dashboard";
import { useCheckForApplicationUpdate } from "./hooks/useCheckForApplicationUpdate";

const checkForNewVersionInterval = 1000 * 60 * 5; // 10 minutes

const RootWrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
  const theme = React.useMemo(() => getTheme(), []);

  return (
    <RecoilRoot>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </RecoilRoot>
  );
};

export const Kiosk = () => {
  useCheckForApplicationUpdate({
    interval: checkForNewVersionInterval,
    onNewVersionAvailable: () => {
      window.location.reload();
    },
  });
  return (
    <RootWrapper>
      <GlobalStyles styles={{}} />
      <Dashboard />
    </RootWrapper>
  );
};
