import * as React from "react";

import { Box, TextField, Button } from "@mui/material";

import { kioskTokenAtom } from "../../state/kioskToken";
import { useRecoilState } from "recoil";

/**
 * if the token is not set, show a form to set it. The form is controlled.
 * The form is made by a single input field with a submit button.
 * The form is centered in the middle of the screen.
 */
export const TokenForm = () => {
  const [kioskToken, setKioskToken] = useRecoilState(kioskTokenAtom);
  const [token, setToken] = React.useState(kioskToken || "");

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setKioskToken(token);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        fontSize: "100%",
        "& form": {
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          backgroundColor: "white",
          padding: "2rem",
          borderRadius: "1rem",
          boxShadow: "0 0 1rem rgba(0, 0, 0, 0.1)",
          "& input": {
            fontSize: "3rem",
            fontFamily: "monospace",
            textAlign: "center",
          },
        },
      }}
    >
      <form onSubmit={onSubmit}>
        <TextField
          value={token}
          onChange={(e) => setToken(e.target.value)}
          variant="outlined"
          size="medium"
          focused
        />
        <Button type="submit" variant="contained">
          Set Token
        </Button>
      </form>
    </Box>
  );
};
