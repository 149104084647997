import React, { useEffect, useState } from "react";

export const NextUpdateCounter: React.FC<{
  nextUpdate: number | undefined;
}> = ({ nextUpdate }) => {
  const [counter, setCounter] = useState(nextUpdate);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((c) => (c ?? 0) - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setCounter(nextUpdate);
  }, [nextUpdate]);

  if (counter === undefined || counter < 0) {
    return <span>updating...</span>;
  }

  return <span>next update in {counter}</span>;
};
