import * as React from "react";

import { z } from "zod";

import { httpClient } from "../../../utils/httpClient";

const Schema = z.object({
  status: z.string(),
  ts: z.string(),
  data: z.object({
    lastAumUsd: z.number().finite(),
  }),
});

type Schema = z.infer<typeof Schema>;

type Data = {
  isLoading: boolean;
  isError: boolean;
  data: Schema | undefined;
};

type Props = {
  token: string | undefined;
  auto?: boolean;
};

export const useKioskData = ({ token, auto }: Props) => {
  const [data, setData] = React.useState<Data>({
    isLoading: false,
    isError: false,
    data: undefined,
  });

  const refresh = React.useCallback(() => {
    if (token === undefined) {
      return;
    }
    setData({
      isLoading: true,
      isError: false,
      data: data.data,
    });

    httpClient
      .get("/kiosk/api", {
        headers: {
          "x-heritage-action": "kiosk",
          authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const result = Schema.safeParse(response.data);
        if (result.success) {
          setData({
            isLoading: false,
            isError: false,
            data: result.data,
          });
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        setData({
          isLoading: false,
          isError: true,
          data: data.data,
        });
      });
  }, [token, data, setData]);

  React.useEffect(() => {
    if (auto && data.data === undefined && !data.isLoading && !data.isError) {
      refresh();
    }
  }, [auto, data, refresh]);

  return { ...data, refresh };
};
