import { httpClient } from "../../../utils/httpClient";
import { useEffect, useRef } from "react";
import { constNull } from "fp-ts/lib/function";

type UseCheckForApplicationUpdateOptions = {
  interval: number;
  onNewVersionAvailable: () => void;
};

const loadPageAsText = async (url: string): Promise<string | undefined> => {
  try {
    const response = await httpClient.get(url);
    if (response.status !== 200) {
      return undefined;
    }
    return response.data;
  } catch (_) {
    return undefined;
  }
};

export const useCheckForApplicationUpdate = (
  options: UseCheckForApplicationUpdateOptions,
) => {
  const pageAsText = useRef<string | undefined>(undefined);
  useEffect(() => {
    const interval = setInterval(() => {
      loadPageAsText(window.location.href)
        .then((lastPageText) => {
          const storeText = pageAsText.current;
          if (storeText !== undefined && storeText !== lastPageText) {
            options.onNewVersionAvailable();
          }
          pageAsText.current = lastPageText;
        })
        .catch(constNull); // ignore errors (i.e connectivity lost)
    }, options.interval);
    return () => clearInterval(interval);
  }, [options]);

  // load the page on start
  useEffect(() => {
    loadPageAsText(window.location.href)
      .then((lastPageText) => {
        pageAsText.current = lastPageText;
      })
      .catch(constNull); // ignore errors (i.e connectivity lost);
  }, []);
};
