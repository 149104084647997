import React from "react";
import { bootstrap } from "../utils/bootstrap";
import "@fontsource/inter/300.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import "@fontsource/playfair-display/500.css";
import { Kiosk } from "../components/kiosk/Kiosk";

bootstrap("app", <Kiosk />);
